<h1>Welcome to a meme card game</h1>

<p>Created in the era of coronavirus, this is a simple online multiplayer card game about memes.</p>
<p>To get started, you just need at least 2 friends (3+ players). You can create a new game by entering your name
    and initials below. Then you just need to share the URL with your friends - they'll be able to join the game until
    you start it.
</p>

<h3>Create a new game</h3>
<div>
    Name:<br />
    <input type="text" [value]="name" (change)="name = $event.target.value" />
</div>
<div>
    Initials (just two please, e.g. JT) <br />
    <input type="text" [value]="initials" (change)="initials = $event.target.value" maxlength="2" />
</div>
<p>&nbsp;</p>
<!--<div>
    Optional - add your own white card: <br />
    <input type="text" [value]="whiteCard" (change)="whiteCard = $event.target.value" />
</div>-->
<div>
    <button (click)="createGame()">Create game</button>
</div>
