import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateGameComponent } from './create-game.component';
import { PlayGameComponent } from './play-game.component';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';



@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    //AngularFireAnalyticsModule,
  ],
  declarations: [
    AppComponent,
    CreateGameComponent,
    PlayGameComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
