import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';


@Component({
  selector: 'app-root',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.css']
})

export class PlayGameComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private firestore: AngularFirestore,
    private analytics: AngularFireAnalytics,
  ) {
    this.db = firestore.firestore;
  }

  shareData: SharingData;

  newPlayer = {
    name: "",
    initials: "",
    score: 0,
    id: null,
    whiteCard: "",
    redealsLeft: 1,
  }

  gameViewModel: GameViewModel = {
    id: null,
    gameOwner: false,
    currentPlayerId: null,
    lastBlackCard: null,
    lastWinningSubmission: null,
    submissions: [],
    logs: [],
    submittedCards: [],
  };

  rules = {
    cardsPerPlayer: 7,
    winningScore: 10,
  };

  players = [];
  game: Game;
  db: firebase.firestore.Firestore;
  gameRef: firebase.firestore.DocumentReference;
  playersRef: firebase.firestore.CollectionReference;
  loggingOn: boolean = false;


  ngOnInit() {

    var that = this;
    var nav = <any>navigator;

    this.shareData = {
      isNavigatorAvailable: typeof (nav.share) === "function",
      title: "offensive.cards online",
      text: "Join me for a game of offensive.cards",
      url: document.location.href,
    }

    this.db = this.firestore.firestore;

    // grab game ID
    this.route.paramMap.subscribe(params => {
      this.gameViewModel.id = params.get("id");
    });

    that.gameRef = this.db.collection("games").doc(this.gameViewModel.id);
    that.playersRef = this.gameRef.collection("players");

    this.subscribeToChanges(this.gameViewModel.id)

  }

  setupPlayer() {

    var that = this;

    var lastCreateGameId = localStorage.getItem("last-create-game-id");
    var lastCreatePlayerId = localStorage.getItem("last-create-player-id");

    if (lastCreateGameId == that.gameViewModel.id) {
      this.gameViewModel.gameOwner = true;
      that.gameViewModel.currentPlayerId = that.players.find(p => p.id == lastCreatePlayerId).id;
    }
    else {
      var lastJoinedPlayerId = localStorage.getItem("last-joined-player-id");
      var existingPlayer = that.players.find(p => p.id === lastJoinedPlayerId);
      this.gameViewModel.currentPlayerId = existingPlayer.id;
    }
  }

  randomOrder(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
    return array;
  }

  subscribeToChanges(gameId) {

    var that = this;

    that.gameRef.onSnapshot(g => {
      // what to do about this?? error
      this.game = <Game>g.data();
      this.log(LogLevel.Info, "Game loaded", this.gameSummary(this.game));
    });

    that.playersRef.onSnapshot(p => {
      this.players = [];
      p.forEach(doc => {
        var player = doc.data();
        // todo - remove id later on update?
        player.id = doc.id;
        this.players.push(player);
      });
      this.log(LogLevel.Info, "Players Loaded", this.players);

      // only run this once
      if (this.setupPlayer) {
        this.setupPlayer();
        this.setupPlayer = null;
      }
    }, e => {
      this.log(LogLevel.Error, "Players snapshot error", e);
    });
  }

  shareUrl() {
    var nav = <any>navigator;
    nav.share({
      text: this.shareData.text,
      title: this.shareData.title,
      url: this.shareData.url,
    });
  }

  joinGame() {
    var that = this;
    // TODO what's the best practice about cleaning IDs
    delete that.newPlayer.id;
    that.playersRef.add(that.newPlayer).then(
      docRef => {
        that.newPlayer.id = docRef.id;
        that.gameViewModel.currentPlayerId = docRef.id;
        localStorage.setItem("last-joined-player-id", docRef.id);

      });
  }

  gameSummary(game: Game) {
    return {
      version: game.version,
      submissions: game.submissions,
      gameState: game.gameState,
      lastWinner: game.lastWinner,
    };
  }

  /*replaceBlanks(blackCardText: string, whiteCards: string[]) {
    var result = blackCardText;
    whiteCards.forEach(c => {
      result = result.replace("_", c);
    });
    return result;
  }*/

  startGame() {
    this.game.gameState = GameState.Selection;

    this.game.currentJudgeId = this.gameViewModel.currentPlayerId;

    this.game.currentBlackCard = this.game.blackCards.pop();

    this.players.forEach(p => {
      if (p.whiteCard && p.whiteCard.length > 1) {
        this.game.whiteCards.push(p.whiteCard)
      }
    });

    this.shuffle(this.game.whiteCards);

    this.deal();

    this.game.version++;
    this.updateGameAndPlayers(this.players);

    // todo - add optional white card.
  }

  nextRound() {
    var nextJudgeIndex = 0;
    var currentJudgeIndex = this.players.findIndex(p => p.id === this.game.currentJudgeId);
    if (currentJudgeIndex < this.players.length - 1) {
      nextJudgeIndex = currentJudgeIndex + 1;
    }

    this.game.currentJudgeId = this.players[nextJudgeIndex].id;
    this.game.submissions = [];
    this.game.currentBlackCard = this.game.blackCards.pop();
    this.game.gameState = GameState.Selection;

    this.deal();

    this.game.version++;
    this.updateGameAndPlayers(this.players);

  }


  shuffle(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  updateGameAndPlayers(players: Array<any>, onError?) {
    var db = this.firestore.firestore;
    var gameRef = db.collection("games").doc(this.gameViewModel.id);
    var playerRefPairs = players.map(p => {
      return {
        player: p,
        ref: gameRef.collection("players").doc(p.id),
      }
    });

    db.runTransaction(tx => {
      return new Promise((resolve, reject) => {
        tx.update(gameRef, this.game);
        playerRefPairs.forEach(n => {
          tx.update(n.ref, n.player);
        });
        resolve();
      });
    }).then(() => {
      this.log(LogLevel.Info, "updateGameAndPlayers Complete", this.gameSummary(this.game));
    }).catch(e => {
      this.log(LogLevel.Error, "updateGameAndPlayers Error", e);
      if (onError) {
        onError(e);
      }
    });
  }

  redeal() {
    if (!window.confirm("Are you sure you want re-deal?") || this.getCurrentPlayer().redealsLeft < 1) {
      return;
    }

    let player = this.getCurrentPlayer();
    var discardedCards = player.cards;
    player.cards = [];
    player.redealsLeft--;

    // take top cards for this player
    while (player.cards.length < this.rules.cardsPerPlayer) {
      player.cards.push(this.game.whiteCards.pop());
    }

    // replace their cards
    discardedCards.forEach(card => {
      this.game.whiteCards.push(card);
    });

    // and shuffle before saving
    this.shuffle(this.game.whiteCards);

    this.game.version++;
    this.updateGameAndPlayers([player], e => {
      alert("Error, please try again");
      this.reload();
    });
  }

  getPlayer(id) {
    return this.players.find(p => p.id === id);
  }

  whiteCardsRequired(text) {
    //return text.split("_").length -1; // CAH multiple cards
    return 1;
  }

  submitCard(whiteCardText, attemptCount?) {

    // remove the card
    var playerCards = this.getCurrentPlayer().cards;
    var cardIndex = playerCards.indexOf(whiteCardText);
    playerCards.splice(cardIndex, 1);

    this.gameViewModel.submittedCards.push(whiteCardText);

    if (this.gameViewModel.submittedCards.length >= this.whiteCardsRequired(this.game.currentBlackCard)) {
      // need to submit more cards
      this.submitAllCards(this.gameViewModel.submittedCards);
    }
  }

  submitAllCards(cards: string[], attemptCount?) {
    // Begin actual upload of submission

    this.gameViewModel.submittedCards = [];

    if (attemptCount == null) {
      attemptCount = 0;
    }

    var submission = {
      playerId: this.gameViewModel.currentPlayerId,
      cards: cards,
    }

    this.log(LogLevel.Info, "Submitting Card", { attemptCount: attemptCount, sub: submission });


    this.game.submissions.push(submission);

    this.game.submissions = this.randomOrder(this.game.submissions);

    if (this.game.submissions.length == this.players.length - 1) {
      this.game.gameState = GameState.Judging;
    }

    if (attemptCount === 0) {
      this.game.version++;
    }

    this.updateGameAndPlayers([this.getCurrentPlayer()], e => {

      // put submitted card back into player deck
      var player = this.getCurrentPlayer();
      submission.cards.forEach(c => {
        player.cards.push(c);
      });

      // reload game from server
      this.gameRef.get().then(doc => {
        this.game = <Game>doc.data();
        // remove submission if it exists
        var index = this.game.submissions.findIndex(s => s.playerId === player.id);

        if (index > -1) {
          this.game.submissions.splice(index, 1);
        }

        var newAttemptCount = attemptCount + 1;
        if (newAttemptCount < 3) {
          this.log(LogLevel.Info, "About to try again... ", { attemptCount: newAttemptCount });
          this.game.version++;
          this.submitAllCards(cards, newAttemptCount);
        }
        else {
          this.log(LogLevel.Error, "Giving up retrying.. ", player);
          this.playersRef.doc(player.id).update(player).then(() => {
            this.log(LogLevel.Info, "Updated Player as fallback", player);
          });
        }

      }).catch(e => {
        this.log(LogLevel.Error, "Game Fetch Error", e);
      })
    });
  }

  reload() {
    document.location.reload();
    return false;
  }

  selectWinner(submission) {
    var winner = this.getPlayer(submission.playerId);
    this.game.lastWinner = submission;
    this.game.lastWinningGif = this.winnerGif();
    winner.score++;
    this.game.gameState = GameState.EndRound;
    this.game.version++;
    this.updateGameAndPlayers([winner]);
  }

  getCurrentPlayer() {
    var that = this;
    return this.players.find(p => p.id === that.gameViewModel.currentPlayerId);
  }

  iHaveSubmitted() {
    return this.game.submissions.findIndex(s => s.playerId === this.gameViewModel.currentPlayerId) > -1;
  }

  hasSubmitted(playerId) {
    return this.game.submissions.findIndex(s => s.playerId == playerId) > -1;
  }

  randomRotation(sway: number) {
    let rotation = (Math.random() * sway) - (sway / 2);
    let styles = {
      'transform': 'rotate(' + rotation + 'deg)'
    };
    return styles;
  }

  log(level: LogLevel, text: string, data: any) {
    if (this.loggingOn == false) {
      return;
    }

    this.gameViewModel.logs.push({
      level: level,
      text: text,
      data: data,
    });
    console.log(text, data);
  }

  winnerGif() {
    var urls = [
      "https://media.giphy.com/media/6oMKugqovQnjW/giphy.gif",
      "https://media.giphy.com/media/5Yl08OSg4AckeNHpDc/giphy.gif",
      "https://media.giphy.com/media/ddHhhUBn25cuQ/giphy.gif",
      "https://media.giphy.com/media/YTbZzCkRQCEJa/giphy.gif",
      "https://media.giphy.com/media/87NS05bya11mg/giphy.gif",
      "https://media.giphy.com/media/8j3CTd8YJtAv6/giphy.gif",
      "https://media.giphy.com/media/jzaZ23z45UxK8/giphy.gif",
      "https://media.giphy.com/media/13hxeOYjoTWtK8/giphy.gif",
      "https://media.giphy.com/media/zQLjk9d31jlMQ/giphy.gif",
      "https://media.giphy.com/media/Bl10664xbIVkk/giphy.gif",
      "https://media.giphy.com/media/4ci8d3rJNrdAY/giphy.gif",
      "https://media.giphy.com/media/PhNgTdjTzXULbKfCnI/giphy.gif",
      "https://media.giphy.com/media/JrLxgem3knPxCTx7Ux/giphy.gif",
      "https://media.giphy.com/media/MZpxwpavVlCE2sRWK3/giphy.gif",
      "https://media.giphy.com/media/K9MPm9A3CaSkw/giphy.gif",
      "https://media.giphy.com/media/7aRG17VMgsGek/giphy.gif",
      "https://media.giphy.com/media/6Yp3H44rgBHZm/giphy.gif",
      "https://media.giphy.com/media/l44Q6Etd5kdSGttXa/giphy.gif",
      "https://media.giphy.com/media/KEVNWkmWm6dm8/giphy.gif",
      "https://media.giphy.com/media/3ohryhNgUwwZyxgktq/giphy.gif",
      "https://media.giphy.com/media/6brH8dM3zeMyA/giphy.gif",
      "https://media.giphy.com/media/rhfxbPtm4m5uo/giphy.gif",
      "https://media.giphy.com/media/1zkMbX7k4nd1AM4i4k/giphy.gif",
      "https://media.giphy.com/media/xT1R9D4BTqrv8nXig0/giphy.gif",
      "https://media.giphy.com/media/dUYf5sSU3qVRCCZMSz/giphy.gif",
      "https://media.giphy.com/media/nqi89GMgyT3va/giphy.gif",
      "https://media.giphy.com/media/UWEcHpY9k3rxe/giphy.gif",
      "https://media.giphy.com/media/3ohzdX7Wzbebc3Y0qA/giphy.gif",
      "https://media.giphy.com/media/3oz8xDLuiN1GcDA3xC/giphy.gif",
      "https://media.giphy.com/media/UdckZOAQrtXMI/giphy.gif",
      "https://media.giphy.com/media/kmqCVSHi5phMk/giphy.gif",
      "https://media.giphy.com/media/ngyRZcPbJim4g/giphy.gif",
      "https://media.giphy.com/media/xMIlfwRRQNEcw/giphy.gif",
      "https://media.giphy.com/media/EWWdvQngcLt6g/giphy.gif",
      "https://media.giphy.com/media/26u4cqiYI30juCOGY/giphy.gif",
      "https://media.giphy.com/media/ReyXCAwJYFm9rb2HxD/giphy.gif",
      "https://media.giphy.com/media/3rXna9rZacY8RUvgTO/giphy.gif",
      "https://media.giphy.com/media/7J7lD2hdRbuxACr6f8/giphy.gif",
      "https://media.giphy.com/media/gEvxDHigKYErhWpQmt/giphy.gif",
      "https://media.giphy.com/media/3ocosrDjgxHDPDqVIA/giphy.gif",
      "https://media.giphy.com/media/W0cDzGKbC1Oh3NqlgX/giphy.gif",
      "https://media.giphy.com/media/skmziDEEjiin6/giphy.gif",
      "https://media.giphy.com/media/26uTrC4SJaczkWqmQ/giphy.gif",
      "https://media.giphy.com/media/fZ1gAEXeCV8F2tAjYr/giphy.gif",
      "https://media.giphy.com/media/IbaY786fr0miugw2Pk/giphy.gif",
      "https://media.giphy.com/media/26AHAw0aMmWwRI4Hm/giphy.gif",
      "https://media.giphy.com/media/l5cjzhHD43vZS/giphy.gif",
      "https://media.giphy.com/media/xHMIDAy1qkzNS/giphy.gif",
      "https://media.giphy.com/media/26BRBKqUiq586bRVm/giphy.gif",
    ];

    return urls[Math.floor(Math.random() * urls.length)];
  }

  deal() {
    var playersAtMax = 0;
    var playerIndex = 0;
    while (playersAtMax < this.players.length) {
      var player = this.players[playerIndex];
      if (!player.cards) {
        player.cards = [];
      }
      if (player.cards.length == this.rules.cardsPerPlayer) {
        playersAtMax++;
      }
      else {
        player.cards.push(this.game.whiteCards.pop());
      }

      // next player
      if (playerIndex == this.players.length - 1) {
        playerIndex = 0
      }
      else {
        playerIndex++;
      }
    }

    // update all
  }
}

interface Game {
  currentJudgeId: string;
  currentBlackCard: string;
  gameState: GameState;
  blackCards: string[];
  whiteCards: string[];
  submissions: Submission[];
  lastWinner: Submission;
  lastWinningGif: string;
  version: number;
}

interface GameViewModel {
  id: string;
  gameOwner: boolean;
  currentPlayerId: string;
  lastWinningSubmission: Submission;
  lastBlackCard: string;
  submissions: Submission[];
  submittedCards: string[];
  logs: LogEntry[];
}

interface Submission {
  playerId: string;
  cards: string[];
}

enum GameState {
  Lobby = "Lobby",
  Selection = "Selection",
  Judging = "Judging",
  EndRound = "EndRound",
  Unknown = "Unknown"
}

interface SharingData {
  title: string;
  text: string;
  url: string;
  isNavigatorAvailable: boolean;
}

interface LogEntry {
  level: LogLevel,
  text: string,
  data: any,
}

enum LogLevel {
  Info = "Info",
  Warn = "Warn",
  Error = "Error",
}
