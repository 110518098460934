<div>
  <div *ngFor="let player of players"
    [ngClass]="{ 'userCircle': true, 'selectedUser' : player.id === gameViewModel.currentPlayerId }">
    {{ player.initials | uppercase }}
    <style>

    </style>
    <div *ngIf="player.id === game.currentJudgeId" class="lilBlackCard"></div>
    <div *ngIf="hasSubmitted(player.id)" class="lilWhiteCard"></div>
    <div class="score">{{ player.score }}</div>
  </div>
</div>


<!-- LOBBY -->
<div *ngIf="game.gameState === 'Lobby'">

  <div *ngIf="gameViewModel.gameOwner === true">
    <p></p>
    Waiting for 2 other players to join...
    <button *ngIf="players.length > 2" (click)="startGame()">Start game</button>
    Invite others to join in by sharing this URL with them:
    <br />
    <div *ngIf="shareData.isNavigatorAvailable">
      <button (click)="shareUrl()">Share</button>
    </div>
    <div *ngIf="!shareData.isNavigatorAvailable">
      <a [href]="shareData.url">{{ shareData.url }}</a>
    </div>

  </div>

  <div *ngIf="gameViewModel.currentPlayerId == null">
    <p>Do you want to join this game?</p>
    <div>
      Name:<br />
      <input type="text" [value]="newPlayer.name" (change)="newPlayer.name = $event.target.value" />
    </div>
    <div>
      Initials (just two please, e.g. JT) <br />
      <input type="text" maxlength="2" [value]="newPlayer.initials"
        (change)="newPlayer.initials = $event.target.value" />
    </div>
    <p>&nbsp;</p>
    <!--<div>
      Optional - add your own white card: <br />
      <input type="text" [value]="newPlayer.whiteCard" (change)="newPlayer.whiteCard = $event.target.value" />
    </div>-->
    <div>
      <button (click)="joinGame()">Join game</button>
    </div>

  </div>

  <div *ngIf="gameViewModel.currentPlayerId != null && gameViewModel.gameOwner === false">
    <p></p>
    Waiting for host to start the game...
  </div>

</div>

<!-- NOT LOBBY -->
<div *ngIf="game.gameState != 'Lobby' && gameViewModel.currentPlayerId == null">
  This game is already in play or completed, sorry!
  <p></p>
  <a href="/">Start a new game!</a>
</div>

<!-- must have currentPlayer for all other states -->
<div *ngIf="gameViewModel.currentPlayerId != null && game.gameState !== 'Lobby'">

  <div *ngIf="game.gameState === 'EndRound'">

    <img src="{{ game.lastWinningGif }}" style="width:280px; margin-top:20px" />





    <div class="pill-container">
      <div class="pill">
        <div class="pill-left">WINNER</div>
        <div class="pill-right">{{ getPlayer(game.lastWinner.playerId).name }}
          ({{ getPlayer(game.lastWinner.playerId).initials | uppercase }})</div>
      </div>
    </div>

    <img src="{{ game.currentBlackCard }}" class="pictureCard" [ngStyle]="randomRotation(3)"/>

    <div id="blackCard" [ngStyle]="randomRotation(6)">
      {{ game.lastWinner.cards }}
    </div>

    <div *ngIf="gameViewModel.currentPlayerId === game.currentJudgeId">
      <button (click)="nextRound()">Next round</button>
    </div>

  </div>


  <!-- Selection or Judging -->
  <div *ngIf="game.gameState === 'Selection' || game.gameState === 'Judging'">

    <img src="{{ game.currentBlackCard }}" class="pictureCard" [ngStyle]="randomRotation(3)"/>

    <!--
    <div id="blackCard" [ngStyle]="randomRotation(2)">
      <div [style.background-image]="'url(' + game.currentBlackCard + ')'" style="width:200px;height:200px;background-size:contain;background-repeat:no-repeat">&nbsp;</div>
    </div>
  -->

    <!-- THE JUDGE-->

    <div *ngIf="gameViewModel.currentPlayerId === game.currentJudgeId">

      <h2>Pick a winner... </h2>

      <!-- waiting for submissions -->
      <div *ngIf="game.gameState === 'Selection'">
        <div *ngIf="game.submissions.length === 0">
          You're judging this round... Just wait it out as the other players submit their entries 🤔
        </div>
        <!-- some entries in... -->
        <p>{{ game.submissions.length }} submission(s) so far...
        </p>
      </div>

      <!-- judging mode -->
      <div *ngIf="game.gameState === 'Judging'">
        <div style="width:100%; overflow-x: scroll;">
          <div *ngFor="let sub of game.submissions" class="cardStack"
            style="padding-right:20px;display:inline-block; vertical-align: top;">
            <div *ngFor="let card of sub.cards" class="whiteCard" [ngStyle]="randomRotation(3)">
              <div (click)="selectWinner(sub)" class="nolink"> {{ card }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PARTICIPANTS -->

    <div *ngIf="gameViewModel.currentPlayerId !== game.currentJudgeId">

      <div *ngIf="game.gameState == 'Selection' && iHaveSubmitted()">
        <h2>You played</h2>
        <div *ngFor="let sub of game.submissions">
          <div *ngIf="iHaveSubmitted() && sub.playerId === gameViewModel.currentPlayerId">
            <div class="judgeStack">
              <div *ngFor="let card of sub.cards" class="judgeCard" [ngStyle]="randomRotation(6)">
                <div class="nolink"> {{ card }} </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="game.gameState == 'Judging'">
        <h2>All entries</h2>
        <div *ngFor="let sub of game.submissions" class="cardStack">
          <div *ngFor="let card of sub.cards" class="whiteCard" [ngStyle]="randomRotation(6)">
            <div class="nolink"> {{ card }} </div>
          </div>
        </div>

      </div>

      <div *ngIf="!iHaveSubmitted() && game.gameState == 'Selection'">
        <div class="judgeStack">
          <div *ngFor="let sub of gameViewModel.submittedCards" class="judgeCard">
            <div class="nolink"> {{ sub }} </div>
          </div>
        </div>
        <h3>Choose</h3>
        <div class="cardStack">
          <div *ngFor="let player of players">
            <div *ngIf="player.id == gameViewModel.currentPlayerId">
              <div *ngFor="let card of player.cards">
                <div [ngStyle]="randomRotation(6)" class="whiteCard">
                  <div (click)="submitCard(card)">{{ card }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style="margin-top:50px">
          You have {{ getCurrentPlayer().redealsLeft }} re-deals left.
          <a [routerLink]="" (click)="redeal()" *ngIf="getCurrentPlayer().redealsLeft > 0">Do it!</a>
        </p>
      </div>
    </div>

  </div>
  <div style="margin-top: 100px;">
    Having trouble? <a href="#" (click)="reload()">Reload</a>.
  </div>


  <div *ngFor="let log of gameViewModel.logs">
    <h3>{{ log.level }}: {{ log.text }}</h3>
    <p>{{ log.data | json }}</p>
  </div>
</div>
