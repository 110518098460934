import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-root',
  templateUrl: './create-game.component.html',
  styleUrls: ['./create-game.component.css']
})

export class CreateGameComponent implements OnInit {

  constructor(
    private firestore: AngularFirestore,
    private router: Router) {
  }

  name: string = "";
  initials: string = "";
  whiteCard: string = "";
  gameId: string;
  gameEngine: GameEngine;

  createGame() {
    if (this.whiteCard !== null && this.whiteCard.length > 1) {
      this.gameEngine.whiteDeck.push(this.whiteCard);
    }

    this.gameEngine.shuffleDecks();

    // todo - validate form input
    this.firestore.collection("games").add({
      gameState: "Lobby",
      blackCards: this.gameEngine.blackDeck,
      whiteCards: this.gameEngine.whiteDeck,
      currentJudgeId: null,
      currentBlackCard: null,
      submissions: [],
      version: 0,
    }).then(docRef => {
      this.gameId = docRef.id;
      localStorage.setItem("last-create-game-id", docRef.id);
      docRef.collection("players").add({
        name: this.name,
        initials: this.initials,
        score: 0,
        redealsLeft: 1
      }).then(docRef => {
        localStorage.setItem("last-create-player-id", docRef.id);
        this.router.navigate(['/games/' + this.gameId]);
      }).catch(error => console.error(error));
    }).catch(error => console.error(error));
  }

  ngOnInit() {
    this.gameEngine = new GameEngine();
    this.gameEngine.makeDecks();
  }
}

class GameEngine {
  rules = {
    cardsPerPlayer: 7,
    winningScore: 10,
  };

  whiteDeck: string[];
  blackDeck: string[];

  makeDecks() {
    this.whiteDeck = whiteCards;
    // todo - only cards that have multiple answers, temporary
    this.blackDeck = blackCards.map(c => {
      return "https://memecards.s3.amazonaws.com/" + c;
    });
  }

  shuffleDecks() {
    for (let i = this.whiteDeck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.whiteDeck[i], this.whiteDeck[j]] = [this.whiteDeck[j], this.whiteDeck[i]]
    }
    for (let i = this.blackDeck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.blackDeck[i], this.blackDeck[j]] = [this.blackDeck[j], this.blackDeck[i]]
    }
  }
}

var blackCards = [
  "Kremlin_cretin.png",
  "Lit_tomb.png",
  "Obama_montage.png",
  "Obama_wink.png",
  "alien_downer.png",
  "angry_pope.png",
  "bad_granny.png",
  "bewildered_gramps.png",
  "birthday_dog.png",
  "bitch_please.png",
  "blissed_out_seal.png",
  "cashed_up_granny.png",
  "cheeseball.png",
  "chill_chihuahua.png",
  "chunky_babba.png",
  "condesending_team.png",
  "confused.png",
  "confused_granny.png",
  "creepy_pepe.png",
  "crying_woman.png",
  "cute_kid.png",
  "depressed_primate.png",
  "depressed_woman.png",
  "devestated_bear.png",
  "dictator_turd.png",
  "disappointing_dude.png",
  "eyeballing_kid.png",
  "fast_chicken.png",
  "fat_baby_dude.png",
  "fat_cat.png",
  "frat_boy.png",
  "funny_dog_face.png",
  "gross_titty_man.png",
  "happy_old_ladies.png",
  "leap_of_faith.png",
  "man_of_whimsy.png",
  "need_some_pepto.png",
  "not_happy_dude.png",
  "not_impressed.png",
  "offensive_chimp.png",
  "pensive_man.png",
  "pepe_finger.png",
  "pepe_tears.png",
  "pig_face.png",
  "plane_dive.png",
  "proud_Biden.png",
  "pug_of_the_sea.png",
  "questioning_girl.png",
  "questioning_guy.png",
  "sad_fox.png",
  "sad_man.png",
  "shart.png",
  "shifty_bird.png",
  "sloth.png",
  "smiley_boy.png",
  "smiley_queen.png",
  "smiling_guy.png",
  "smug_pussy_bitch.png",
  "spacey_owl.png",
  "spill_the_tea.png",
  "stalker_Joe.png",
  "stop_the_nut.png",
  "stroppy_kid.png",
  "stuffed_cat.png",
  "suggestive_granny.png",
  "suicidal_pepe.png",
  "teary_chihuahua.png",
  "this_is_fine.png",
  "threatening_child.png",
  "triumphant_woman.png",
  "veiny.png",
  "watch_it_burn.png",
  "wonderous_pug.png",
];

var whiteCards = [
  "When you hit the last lap in Mario Kart and the music speeds up",
  "When you're jamming out in your car with your mom and Afroman screams \"ate that pussy like shrimp fried rice!\"",
  "When you eat peanut butter too fast and regret ever being born",
  "When your doctor asks if you're sexually alive",
  "When your only friend is busy",
  "When he finally hits the clit after 25 minutes",
  "When you send him a selfie with no makeup and he asks \"who's that?\"",
  "When you see someone bite into a string cheese",
  "When you're listening to a song and the lyrics start applying perfectly to your life",
  "When your boyfriend's biggest fantasy is football",
  " When you eat all the pizza rolls and remember the good times when you had pizza rolls",
  "When someone asks if you voted for Donald Trump",
  "When you hear someone say that Kim Kardashian inspires them",
  "When you find out that girls poop",
  "When you get an erection that lasts longer than 4 hours",
  "When you see someone pour milk in their bowl before the cereal",
  "When you're talking to someone and accidentally spit in their face",
  "When someone prettier than you calls you pretty",
  "When you're hungry and your mom says \"why don't you have some fruit?\"",
  "When you realize Space Jam came out over 20 years ago",
  "When you can't stop laughing during a fucked up situation ",
  "When you realize you've just watched 8 straight episodes of Diners, Drive-Ins and Dives",
  "When you're not sure whether to shut the fuck up or go straight savage mode on a mothafucka",
  "When she wants to meet your dad but so do you",
  "When you're watching an R-rated movie and a titty pops out",
  "When you're playing What Do You Meme? and have no cards that relate to the photo",
  "When you hear your parents having passionate intercourse",
  "When you get a a painful front wedgie",
  "When you had braces for 4 years and your teeth are still fucked up",
  "When your cheating on a test and make eye contact with the teacher",
  "When you accidentally send your grandma a dick pic",
  "When you fart and a little turd comes out",
  "When you try anal for the first time",
  "When it's 4/20 and all the cool kids are blazing so you take a hit to fit in and then die",
  "When your stepdad Rick drinks all your Mountain Dew",
  "When you've had to buy Plan B so often it's becoming Plan A",
  "When someone tells you that you look tired",
  "When you realize you just watched Kylie Jenner make a pancake on Snapchat for 15 minutes",
  "When he said he'd text you after work but that was a year ago",
  "When you're watching a movie with your parents and there's a sex scene",
  "When he says Fifty Shades of Grey is his favorite book",
  "When it's the first day of Spring Break and you get your period",
  "When you're watching Chopped and the chef decides to make a vinaigrette with less then a minute left",
  "When your brother's a doctor, your sisters a lawyer, and you just finished a bottle of wine all by yourself",
  "When the shrooms finally kick in",
  "When you hear someone talking shit about former U.S. Vice President Joe Biden",
  "When your ex asks you how you've been lately",
  "When your lasagna sounds like WWII in the microwave but comes out cold",
  "When you see a comma in your bank account",
  "When you meet someone you've stalked on social media and you're not sure if you should play it cool or ask them how Cabo 2012 was",
  "When you're drowning in life's responsibilities but don't give a single fuck",
  "When all your classmates are eating Lunchables but your mom packed you organic cottage cheese and baby carrots",
  "When your meeting is almost over and somone asks a question that extends it by 20 minutes",
  "When your dog saw you masturbating and you're not quite sure if you guys are cool again or not",
  "When your swimming and your foot grazes some seaweed",
  "When your friend who lies about everything starts telling a story",
  "When your friend who's a fuck-up starts giving you advice",
  "When you find out that your cheating ex broke their leg",
  "When you realize there are 7 billion people on this planet and you still can't find someone to enjoy a taco with",
  "When you see a cute service dog but can't pet it because you know it's been training its whole life for this",
  "When you fart in someone else's car and all the windows are rolled up",
  "When it says 4-7 business days to ship and you were counting on 4 but it's looking like it's going to be 7",
  "When the music festival's over, the drugs have worn off, and reality is all that's left",
  "When you're pissing in a pool and have to pretend like you're not pissing in a pool",
  "When his roomate says \"oh you must be the girl he's always Facetiming with\" but you have an Android",
  "When you start a new diet on Thursday but then spend the entire weekend blacked out covered in pizza",
  "When your friend who died at the pregame shows up at the bar",
  "When she's driving you to your homies house and you stopped giving her directions 3 turns ago but she's still going the right way",
  "When you get home and your roommate's eating the tomato quiche you were saving from the night before",
  "When someone irrelevant speaks",
  "When you find 11 McNuggets in your 10-piece meal",
  "When you wake up with gum stuck in your pubes",
  "When you're 3 Four Lokos deep and  a Creed song comes on",
  "When your neighbor starts mowing their lawn before 9am on Sunday",
  "When you stub your toe",
  "When you fall so hard for a fuckboy that even Life Alert can't save you",
  "When you get sand in your vagina",
  "When you meet someone who is down for froyo and missionary intercourse",
  "When you find out he still lives with his parents",
  "When you realize Guy Fieri makes millions of dollars a year by eating food",
  "When your boss is always cracking lame jokes but you can't afford to lose your job",
  "When your friends want to split it evenly but you only had an appetizer",
  "When it's Monday morning and you're deciding whether to go to work or call in dead",
  "When you called shotgun but the backseat ends up being way more lit",
  "When you realize you peaked in high school",
  "When your boss dies",
  "When he's going out with his boys at 10pm so you schedule an argument at 9:45pm",
  "When your Uber driver won't shut the fuck up",
  "When you hear someone say \"moist\"",
  "When you look in the mirror at the bar and realize you're completely shitfaced",
  "When you're trying to lose weight but gain 3 pounds every time you smell a cookie",
  "When you come back from vacation and your guinea pig is dead",
  "When you finally get a text but it's your service provider saying you've used 90% of your data this month",
  "When you're cutting wrapping paper and the scissors start to glide",
  "When the bouncer accepts your fake ID",
  "When you go to the bathroom during hibachi and miss the onion volcano",
  "When you take her to hammer town for 45 seconds and wonder why she didn't cum",
  "When you tuck your boner into your waistband and feel sneaky as fuck",
  "When you relate to every meme but then realize every meme is about being lazy, overweight, or an alcoholic",
  "When you introduce 2 of your friends and they start hanging out without you",
  "When your mom says you can't go so you ask your dad and he says you can",
  "When you catch your reflection in a store window and realize being born was a huge mistake",
  "When restaurants put calorie counts on their menu",
  "When you rap the whole verse correctly ",
  "When you're on a first date and they start talking about horoscopes",
  "When people use their zodiac sign to justify their behavior",
  "When you have to explain to your therapist what a meme is",
  "When you find out the guy your dating has 3 kids",
  "When you remember what you looked like in 3rd grade",
  "When the kid behind you in Social Studies puts their pencil up your butt crack",
  "When you're down in the dumps but then remember you weren't born in North Korea",
  "When someone uses your driveway to turn around",
  "When it's been 42 minutes since your last meal",
  "When you match with your therapist on Tinder",
  "When your anxiety goes away but having no anxiety gives you anxiety",
  "When you step on a Lego",
  "When the guy you fucked last night wants to connect on LinkedIn",
  "When you squeeze the ketchup and the bottle farts",
  "When you take a picture with your favorite celebrity but you look to ugly to post it",
  "When the waiter stops shredding the parmesan cheese but you haven't said \"when\" yet",
  "Your face when you send the first freaky text and wait for a reply",
  "When everyone when else at the starting line is Jamaican",
  "When you want to start a conversation with someone but you're fresh out of Xanax",
  "When someone who works out starts talking about their workouts",
  "When you go with almond milk over regular milk and feel healthy as fuck",
  "When your mom tries to be friends with you after yelling at you for not taking the hamburger patties out of the freezer like she asked",
  "When you take too much acid before your nephew's baptism",
  "When you bleach your asshole and start demanding more respect around here",
  "When the person you hooked up with once invites you over to meet their parents",
  "When you throw something in the garbage and shout \"Kobe!\" but deep down you know you're not Kobe",
  "When your fucks-to-give balance is dangerously low",
  "When they come back from the bathroom and see that their phone is disabled 196 hours",
  "When you go to a Halloween party as The Hulk and people keep saying they love your Shrek costume ",
  "When you look in the mirror and think \"fuck you\"",
  "When all the drugs and alcohol hit you at once and you gotta stay focused on not dying",
  "When someone who screwed you over tries to come back into your life but you already talked shit about them to your mom",
  "When your outfit's on point but your date cancels on you last minute",
  "When someone is giving you directions and starts using words like \"East\" and \"West\"",
  "When you run into someone you barely knew in high school and they say \"hit me up sometime, we gotta chill\"",
  "When you finish a math problem and everyone's arguing whether the answer was 66 or 67 but you got -4",
  "When you hit snooze 22 times and now you have 3 minutes to leave the house",
  "When you get a notification but it's from Team Snapchat",
  "When you open a bag of chips and there are only 6.5 chips inside",
  "When you see your side chick strike up a conversation with your main chick",
  "When you use \"thus\" in an essay",
  "When someone says \"check out my blog\"",
  "When you think he's gonna propose but he's just tying his shoe",
  "When you text her \"Good morning beautiful\" and she replies with \"Aww thanks buddy\"",
  "When you finally get some booty after a 2 month drought",
  "When you don't check your phone for 3 hours and feel spiritual as fuck",
  "When you get money but still don't fuck bitches",
  "When you're not in a relationship but you're having relationship problems",
  "When he apologizes but you still have some more arguing to do",
  "When you check Facebook and see your grandson wearing that sweater you knit him but the album is \"Ugly Sweater Party\"",
  "When your new girlfriend is really good at blowjobs but then you remember practice makes perfect",
  "When your mom adds you on Snapchat",
  "When you randomly remember how small your penis is",
  "When your president tries to grab you by the pussy",
  "When someone asks if you're sober enough to drive",
  "When you sit down after somebody and the seat is warm",
  "When you're bored and someone tells you to read a book",
  "How white people smile at each other on the street",
  "When you google the lyrics and realize you've been singing nonsense this whole time",
  "When you're ordering a pizza and your broke friend screams \"get wings, too\"",
  "That face you make while people are singing you \"Happy Birthday\"",
  "When you try to open a jar of pickles and realize you're not a strong independent woman who don't need no man",
  "When your best friend fucks your sibling",
  "When all the old toys your mom threw out are suddenly worth thousands of dollars ",
  "When your ex dies in a freak masturbation accident",
  "When you're chilling with someone and think about how you never want to see their face again ever again",
  "When you walk in on your grandma riding your grandpa in reverse cowgirl",
  "When the neighborhood pervert molests every kid but you",
  "When you see your ex with the person they told you not to worry about",
  "When you're not mad just disappointed",
  "When you find out you're adopted",
  "When someone on the plane claps after landing",
  "When you discover that you and your dad have the same taste in internet porn",
  "When someone congratulates you on being pregnant but you're not pregnant",
  "When she tells you to go deeper but you've run out of dick ",
  "When you're so single you don't have anyone to drunk text",
  "When your daughter tells you she wishes she didn't exist",
  "When your fart smells gourmet",
  "When there's no toilet paper left so you have to twerk",
  "When you check the time at work and it's 10am and then check again 3 hours later and it's 10:15am",
  "When you successfully make pasta without burning the house down",
  "When you try a risky new fashion trend and the streets love it",
  "When you masturbate with your other hand and feel like you're cheating on yourself",
  "When your friends are all starting to have kids, but you can't manage to keep a cactus alive",
  "When you come to terms with the fact that you'll never be able to dunk a basketball",
  "When you're all ready to go out but then realize you have no money, no plans, and no friends",
  "When the unsaved number in your phone starts catching feelings",
  "When you find a dildo in your mom's sock drawer",
  "When you mistake a used pregnancy test for a thermometer",
  "When your mom has a camel toe but telling her would be more awkward than staring at it",
  "When your parents forgot to leave you a trust fund",
  "When your tampon accidentally hits your G-spot",
  "When a dog dies in a movie",
  "When you see all your friends prancing around in their bikinis but you can't participate because pizza",
  "When you zone out the entire drive and wonder how the fuck you got home",
  "When you realize you've seen the pizza guy more than your friends lately",
  "When your Netflix suggestions are about whale rape",
  "When you show up on a blind date and they're wearing a fedora",
  "When you mix up your clean and dirty laundry and you're pretty sure you're wearing your cum rag",
  "When you're trying to have fun but remember how sad your dog looked when you left the house",
  "When your soulmate comes into your life before you're done being a hoe",
  "When your going down on someone and hear a suspicious noise",
  "When you get a rough handjob at the movies",
  "When you get a boner at your cousin's bar mitzvah",
  "When the Girls Gone Wild commercial comes on",
  "When you watch \"2 girls 1 cup\" for the first time",
  "When you say you speak a little Spanish and they try having a full-blown Spanish conversation with you",
  "When you lay down for a quick nap and wake up 8 hours later",
  "When you find out that bees are dying at an alarming rate",
  "When you're as high a kite and start wondering who closes the bus door after the driver gets off",
  "When you just met someone and they start yelling you some super intense shit",
  "When it's April 2nd and she's still pregnant",
  "When you open a birthday card and your grandma and no money falls out",
  "When you're signing up for a free trial and they make you enter your credit card info ",
  "When you just proved a point via text and see those 3 little dots appearing and disappearing cuz you're right",
  "When you say something funny and nobody hears it, then someone repeats it and everyone laughs",
  "When you have a headache so you check WebMD and discover you're dying ",
  "When you hear a recording of your own voice",
  "When your sister says \"leave me alone\" but you're an annoying fuck so too bad, sis",
  "When you invite them over to Netflix & Chill knowing damn well you don't have Netflix",
  "When your little sister's boyfriend just took her to Paris, meanwhile Craig from the bar just texted you \"Yo, you still up?\"",
  "When your last sexual encounter was with TSA",
  "When you accidentally step on your cat ",
  "When you're a vegan and haven't told anyone in 7 minutes",
  "When you're taking a poop and forget your phone outside the bathroom ",
  "When you get your first wet dream ",
  "When your shampoo says \"Damage Control\" but you're still broken on the inside",
  "When your leaked sex tape goes viral in Lithuania",
  "When you're a kid in Stuart Little's orphanage and someone picks a mouse over you",
  "When you're about to nut but the porn video's plot thickens",
  "When your grandma walks in on you jerking off",
  "When you see the first question on the test and realize you're completely fucked ",
  "When you're getting road head and your rabbi drives by",
  "When she tweets \"My stomach hurts\" and you can't remember if you you pulled out or not",
  "When that one overly cheerful co-worker gets to the office and starts annoying the fuck out of you",
  "When your dentists asks if you floss daily ",
  "When you thought someone was chill but then you see them put ketchup on their pasta",
  "When your butthole's super itchy and you fart hard enough to scratch it",
  "When the table next to you orders after you but gets their food first",
  "When you rip the most profound fart of your life ",
  "When you queef in public",
  "When you're mad at someone but then they ask if you want some food",
  "When your douchebag friend pokes holes in all your condoms and you end up getting his girl pregnant",
  "When your new friend asks if you wanna do karate in the garage",
  "When you're sitting there after some nasty sex wondering if you still have a place in heaven",
  "When you look at your boyfriend after watching The Notebook and realize he's not Ryan Gosling",
  "When everyone at lunch is talking about what they got for Christmas but you're Jewish",
  "When the flight attendant wakes you up from your coma to offer you a bag of stale peanuts",
  "When it's your birthday and your Facebook wall reminds you that you have friends",
  "When you block your crazy ex on everything and they send you an invitation to connect on LinkedIn ",
  "When you spell a word so wrong that even spell check can't figure out what the fuck you're trying to say",
  "When you peel a banana and there's a bruise on it",
  "When you walk into someone's room and they have a \"Live, Laugh, Love\" sign above their bed",
  "When you flip the pillow over to the cold side",
  "When the friend you're supposed to go out with tonight starts talking about how tired they are",
  "When you're hanging out with a couple and they start fighting",
  "When your black friend calls you the N-word for the first time",
  "When your drug dealer starts talking about things other than drugs",
  "When your mom walks in on you practicing giving a blowjob on a cucumber",
  "When you show someone a post you laughed really hard at and they don't laugh",
  "When you lose something and someone says \"where'd you lose it?'",
  "When you wake up after blacking out and realize your Snapchat story is 11 minutes long",
  "When you hear a sad song and pretend to be in the music video ",
  "When you get to the party and immediately have to shit",
  "When the waitress tells you to enjoy your meal and you say \"thanks, you too\"",
  "When you forgot to turn on your Wi-Fi and realize you just used all your monthly data in the first week",
  "When you start telling your parents a story and then remembered what happened was illegal",
  "When someone calls you instead of text",
  "When someone says they listened to a song before it was popular",
  "When you hang out with someone without drinking and have no idea what to talk about",
  "When the dude in front of you at Starbucks is ordering for his whole office",
  "When your grandma asks you to help her with technology",
  "When you think you've been charging your phone for the last 3 hours but it wasn't plugged in",
  "When you wake up in the morning and gotta remember if you're depressed or not",
  "When you want hard, degrading sex but he just wants to make love",
  "When you get too high and wait for the stop sign to turn green",
  "When you sneeze and nobody says \"bless you\"",
  "When the neighborhood bully kicks you in the shin",
  "That face you make when you change your Facebook status from \"In a relationship\" to \"Single\" ",
  "When you're 3 drinks deep and feel your inner hoe starting to come out",
  "When you shave your legs but don't get any dick all weekend",
  "When the Facetime call says \"connecting\"",
  "When you hit your funny bone and it ain't fuckin' funny",
  "When someone says they're more of a cat person than a dog person",
  "When you try to send a dick pic and it says \"file too large\"",
  "When you hear your mom bragging about you to her book club",
  "When she calls your penis \"cute\"",
  "When all of your boyfriends are at the same bar",
  "When she licks your anal cavity during foreplay",
  "When you're always a bridesmaid but never a bride",
  "When you wake up half-naked in a McDonalds bathroom",
  "When you go to get your eyebrows waxed and they say \"upper lip, too?\"",
  "When your vibrator runs out of battery right before you finish",
  "That face you make while helping your son look for his halloween candy you ate last night",
  "When your crush borrows your ruler and asks why there's a mark at 2.5 inches",
  "When your wife gives birth to an Asian baby when neither of you are Asian",
  "When your girlfriend suggest moving in together",
  "When someone says they love running",
  "When your health teacher makes extended eye contact with you while rolling a condom onto the bathroom",
  "When you can finally take the tissues out of your bra after a long day",
  "When you're about to cum and Bill Nye the Science Guy pops into your head",
  "When your voice cracks during erotic phone sex",
  "When you have to be funny because being hot isn't an option",
  "When you sprout your first pube",
  "When you gotta pay taxes but all school taught you is how to calculate the circumference of a parallelogram",
  "When you nut but she keeps sucking",
  "When all your friends are getting married and you're still giving head in the backseat of cars",
  "When you're turnt as fuck at 3am and remember you have leftover Domino's in the fridge",
  "When someone says they don't fuck on the first date",
  "When you realize that the most supportice person in your life is DJ Khaled",
  "When someone wishes you a Happy Kwanzaa but your last name is Bernstein",
  "When you're stoned to the bone and start wondering why pizza boxes are square when pizza is circular",
  "When you think someone's waving at you so you wave back but then you realize they were waving at the person behind you",
  "When you blackout at the holiday party and wake up next to your manager",
  "When you're all ready to go out but your phones at 6%",
  "When all the pending transactions from the weekend hit your bank account at once",
  "When you find the perfectly ripe avocado at the supermarket",
  "When your crush texts you cute things like \"Who's this?\" and \"How'd you get this number?\"",
  "When you find the perfect rock to skip but fuck up the throw",
  "When your hairdresser spins you around to reveal they've ruined your fucking life",
  "When you're giving a presentation and someone says \"speak up\"",
  "When you're so broke that you have to go to sleep for dinner",
  "When you're trick-or-treating and someone gives you a box of raisins",
  "When you lied on your resume about being proficient in Excel",
  "When your crush says they're stuck at the office but their Snapchat is full of adventures",
  "When you're doing the dishes and touch some soggy food",
  "When you're trying to relax but can't stop thinking about how ugly you are",
  "When your Five-Dollar Footlong comes out to $11.39",
  "When you say \"bye\" to someone then both walk in the same direction",
  "When your tampon string makes its way into your bootyhole",
  "When you're hitting it raw and she says \"cum inside me Daddy\"",
  "When you get to work on Monday morning and your boss is still alive",
  "When your gynecologist gives you a high five",
  "When your dentist suggests adult braces",
  "When the plane landed 5 minutes ago and you still have 0 texts",
  "When you're ordering pizza and someone suggests pineapple as a topping",
  "When the light just turned green and the car behind you is already beeping",
  "When your STD test comes back positive",
  "When the dentist asks you a question while their hands are in your mouth",
  "When you pop a boner while watching a Disney movie",
  "When you throw yourself a birthday party and only your mom shows up",
  "When you're sitting at his funeral realizing you went too far during the break-up",
  "When someone you thought you trusted doesn't know the words to the Spongebob theme song",
  "When it's winter so you don't have to shave your legs anymore",
  "When the delivery guy gives you 3 sets of utensils and it feels like a personal attack",
  "When you gain 10 pounds for a role and then remember you're not an actor",
  "When your grandma sneaks $40 into your pocket on your way out the door",
  "When the teacher thinks you're not listening so they ask you a question and you get it right",
  "When someone asks if you'd step on your ex for $1,000 but you'd literally stab them in the neck for $10",
  "When your mom tells the whole family you have a UTI",
  "When your math teacher says to cross multiply but you don't know anyone named multiply",

]
